<template>
  <div class="app-loading__container" v-if="isVisible">
    <div class="app-loading__spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
    <div class="app-loading__overlay"></div>
  </div>
</template>

<script>
export default {
  name: 'AsyncLoader',
  computed: {
    requestsCount() {
      return this.$store.getters.requestsCount;
    },
    isVisible() {
      return this.requestsCount > 0;
    },
  },
};
</script>

<style lang="scss">
.app-loading {
  &__spinner {
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -16px 0 0 -60px;
    width: 120px;
    z-index: 101;
    .bounce1 {
      animation-delay: -0.32s;
    }
    .bounce2 {
      animation-delay: -0.16s;
    }
  }
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100
  }
}

.app-loading__spinner > div {
  width: 32px;
  height: 32px;
  background-color: #006AFF;
  border-radius: 100%;
  display: inline-block;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1.0);
  }
}
</style>
