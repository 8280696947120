<template>
  <div class="header__login">
    <dropdown-menu class="user-menu-dropdown" :overlay="false">
      <a href="#" slot="trigger">
        {{user.fullName}}
        <img src="../../assets/img/IconUser.svg" alt="Vector" class="header__img">
      </a>
      <ul class="header__login__dropdown" slot="body">
<!--        <li>-->
<!--          <a href="#">Профиль</a>-->
<!--        </li>-->
        <li>
          <a href="#" @click.prevent="logout">Выйти</a>
        </li>
      </ul>
    </dropdown-menu>
  </div>
</template>

<script>
export default {
  name: 'UserMenu',
  computed: {
    user() {
      return this.$auth.user();
    },
  },
  methods: {
    logout() {
      this.$emit('logout');
    },
  },
};
</script>
