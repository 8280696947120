import Vue from 'vue';
import VueRouter from 'vue-router';

import Info from '../views/Info.vue';
import Book from '../views/Book.vue';
import Catalog from '../views/Catalog.vue';
import Contacts from '../views/Contacts.vue';
import News from '../views/News.vue';
import Login from '../views/Login.vue';
import NotFound from '../views/NotFound.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Info,
  },
  {
    path: '/catalog',
    name: 'catalog',
    component: Catalog,
  },
  {
    path: '/book',
    name: 'book',
    component: Book,
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contacts,
  },
  {
    path: '/news',
    name: 'news',
    component: News,
  },
  {
    path: '/login',
    name: 'auth:login',
    component: Login,
  },
  {
    path: '*',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: 'active',
});

export default router;
