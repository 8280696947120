<template>
  <div class="header">
    <div class="hamburger-menu">
      <input id="menu__toggle" type="checkbox" v-model="dropdownMenu" />
      <label class="menu__btn" for="menu__toggle">
        <span></span>
      </label>
      <ul class="menu__box">
        <li>
          <a
            href="#"
            :class="{active: routeActive('home')}"
            @click.prevent="go({name: 'home'})">Инфо</a>
        </li>
        <li>
          <a
            href="#"
            :class="{active: routeActive('catalog')}"
            @click.prevent="go({name: 'catalog'})">Караоке</a>
        </li>
        <li v-if="isEnabled('news')">
          <a
            href="#"
            :class="{active: routeActive('news')}"
            @click.prevent="go({name: 'news'})">Новости</a>
        </li>
        <li v-if="isEnabled('contacts')">
          <a
            href="#"
            :class="{active: routeActive('contacts')}"
            @click.prevent="go({name: 'contacts'})">Контакты</a>
        </li>
        <li v-if="isEnabled('book')">
          <a
            href="#"
            :class="{active: routeActive('book')}"
            @click.prevent="go({name: 'book'})">Забронировать</a>
        </li>
        <template v-if="!$auth.check()">
          <li>
            <a
              href="#"
              :class="{active: routeActive('auth:login')}"
              @click.prevent="go({name: 'auth:login'})">Войти</a>
          </li>
        </template>
        <template v-else>
          <li>
            <a href="#" @click.prevent="logout">Выйти</a>
          </li>
        </template>
        <p-w-a-p-rompt></p-w-a-p-rompt>
      </ul>
    </div>
    <ul class="header__menu">
      <li>
        <router-link :to="{name: 'home'}" exact>Инфо</router-link>
      </li>
      <li>
        <router-link :to="{name: 'catalog'}" exact>Караоке</router-link>
      </li>
      <li v-if="isEnabled('news')">
        <router-link :to="{name: 'news'}">Новости</router-link>
      </li>
      <li v-if="isEnabled('contacts')">
        <router-link :to="{name: 'contacts'}">Контакты</router-link>
      </li>
      <li v-if="isEnabled('book')">
        <router-link :to="{name: 'book'}">Забронировать</router-link>
      </li>
    </ul>
    <guest-menu v-if="!$auth.check()"></guest-menu>
    <user-menu @logout="logout" v-else></user-menu>
  </div>
</template>

<script>
import GuestMenu from '@/components/menu/GuestMenu.vue';
import UserMenu from '@/components/menu/UserMenu.vue';
import PWAPRompt from '@/components/PWAPRompt.vue';

export default {
  name: 'Menu',
  components: { GuestMenu, UserMenu, PWAPRompt },
  data() {
    return {
      dropdownMenu: false,
    };
  },
  computed: {
    menuConfig() {
      return this.$store.getters.appConfig('menu');
    },
  },
  methods: {
    logout() {
      this.$auth.logout({ makeRequest: true });
    },
    routeActive(name) {
      return this.$route.name === name;
    },
    go(route) {
      this.dropdownMenu = false;
      this.$router.push(route);
    },
    isEnabled(item) {
      return !!this.menuConfig[item];
    },
  },
};
</script>
