import Vue from 'vue';

import axios from 'axios';
import VueAxios from 'vue-axios';
import auth from '@websanova/vue-auth/src/v2';
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x';

import VueTheMask from 'vue-the-mask';

import vmodal from 'vue-js-modal';

import DropdownMenu from 'v-dropdown-menu';
import 'v-dropdown-menu/dist/v-dropdown-menu.css';

import App from './App.vue';
import router from './router';
import store from './store';

import './assets/scss/app.scss';
import './registerServiceWorker';

axios.defaults.headers.common['X-CSRF-Token'] = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
axios.defaults.baseURL = '/api';
axios.interceptors.response.use((response) => {
  store.commit('downRequests');
  return response;
}, (error) => {
  store.commit('downRequests');
  return Promise.reject(error);
});
axios.interceptors.request.use((config) => {
  store.commit('upRequests');
  return config;
}, (error) => {
  store.commit('downRequests');
  return Promise.reject(error);
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(DropdownMenu);
Vue.use(VueTheMask);
Vue.use(vmodal);

Vue.use(auth, {
  plugins: {
    http: axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    notFoundRedirect: { name: 'user-account' },
    authRedirect: {
      name: 'auth:login',
    },
    refreshData: {
      enabled: false,
    },
    loginData: {
      url: '/auth/login',
      fetchUser: true,
    },
    logoutData: {
      url: '/auth/logout',
      makeRequest: true,
    },
    fetchData: {
      url: '/auth/user',
      makeRequest: false,
    },
    parseUserData(data) {
      return data;
    },
  },
});

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  components: { App },
  // render: (h) => h(App),
});
