<template>
  <div class="contacts">
    <div class="contacts__contact">
      <h1 class="main-title">Контакты</h1>
      <h3 class="contacts__number">
        <span>Телефон:</span> {{phone}}<br>
        <span>Адрес:</span> {{address}}
      </h3>
      <div class="contacts__text" v-html="bottomText" v-if="has('bottomText')"></div>
    </div>
    <div class="contacts__map" v-if="has('mapUrl')">
      <h2 class="contacts__maps">
        Как нас найти
      </h2>
      <div class="map">
        <iframe
          title="Yandex maps"
          :src="mapUrl"
          frameborder="0"></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    params() {
      return this.$store.getters.appConfig('contacts');
    },
    phone() {
      return this.params.phone || null;
    },
    address() {
      return this.params.address || null;
    },
    mapUrl() {
      return this.params.map_url || null;
    },
    bottomText() {
      return this.params.bottom_text || null;
    },
  },
  methods: {
    has(param) {
      return this[param] !== null && this[param].length > 0;
    },
  },
};
</script>

<style scoped>

</style>
