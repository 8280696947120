<template>
  <div class="login">
    <h1 class="main-title">Войти</h1>
    <form class="login__form" @submit.prevent="">
      <div>
        <div class="form-group">
          <float-label label="Телефон" :show="!!form.phone">
            <input
              class="login__input"
              type="text"
              v-mask="'+7 (###) ###-##-##'"
              v-model="form.phone"
              required="required"
              placeholder="+7 (999) 999-99-99"
              @input="clearErrors('phone')">
          </float-label>
          <field-error :error="getErrors('phone')"></field-error>
        </div>
        <button
          :disabled="sendCodeBtnDisabled"
          class="login__btn send-code-btn"
          @click.prevent="sendCode"
          type="button">{{sendCodeBtnText}}</button>
      </div>
      <template v-if="codeSent">
        <p>
          На ваш телефон поступит звонок.
          Последние 4 цифры телефона, с которого будет звонок, являются кодом подтверждения.
          Введите эти 4 цифры в поле ниже
        </p>
        <div class="form-group">
          <float-label label="Код" :show="!!form.code">
            <input
              class="login__input"
              type="text"
              v-mask="'####'"
              v-model="form.code"
              required="required"
              @input="clearErrors('code')"
              placeholder="Код">
          </float-label>
          <field-error :error="getErrors('code')"></field-error>
        </div>
        <template v-if="needRegister">
          <div class="form-group">
            <float-label label="Фамилия" :show="!!form.lastName">
              <input
                class="login__input"
                type="text"
                v-model="form.lastName"
                @input="clearErrors('lastName')"
                placeholder="Фамилия">
            </float-label>
            <field-error :error="getErrors('lastName')"></field-error>
          </div>
          <div class="form-group">
            <float-label label="Имя" :show="!!form.firstName">
              <input
                class="login__input"
                type="text"
                v-model="form.firstName"
                @input="clearErrors('firstName')"
                placeholder="Имя">
            </float-label>
            <field-error :error="getErrors('firstName')"></field-error>
          </div>
        </template>
        <div class="form-group login__eula">
          <label for="eula">
            <input id="eula" type="checkbox" v-model="eula">
            Я принимаю условия <a href="/terms_of_use.pdf" target="_blank">пользовательского соглашения</a>
            и даю согласие на <a href="/privacy_policy.pdf" target="_blank">обработку персональных данных</a>
          </label>
          <field-error :error="getErrors('eula')"></field-error>
        </div>
        <button
          v-if="codeSent"
          class="login__btn"
          @click.prevent="login"
          type="button">Войти</button>
      </template>
    </form>
  </div>
</template>

<script>
// noinspection ES6CheckImport
import FloatLabel from '@/components/FloatingLabel.vue';
import formMixin from '@/components/formMixin';
import FieldError from '@/components/FieldError.vue';

export default {
  components: { FieldError, FloatLabel },
  mixins: [formMixin],
  data() {
    return {
      form: {
        phone: '',
        code: '',
        firstName: '',
        lastName: '',
      },
      eula: false,
      codeSent: false,
      needRegister: false,
      countdownInterval: null,
      countdown: 60,
    };
  },
  computed: {
    computedCountDown() {
      return parseInt(this.$store.getters.appConfig('sms_confirm_timeout'), 10);
    },
    sendCodeBtnText() {
      if (this.countdownInterval !== null) {
        return `${this.countdown} сек.`;
      }
      return 'Отправить код';
    },
    sendCodeBtnDisabled() {
      return this.countdownInterval !== null;
    },
  },
  methods: {
    startCountDown() {
      this.countdownInterval = setInterval(() => {
        this.countdown -= 1;
        if (this.countdown <= 0) {
          this.stopCountdown();
        }
      }, 1000);
    },
    stopCountdown() {
      if (this.countdownInterval) {
        clearInterval(this.countdownInterval);
        this.countdownInterval = null;
        this.countdown = this.computedCountDown;
        this.clear(true);
      }
    },
    clear(noClearPhone = false) {
      if (!noClearPhone) {
        this.form.phone = '';
      }
      this.form.code = '';
      this.form.firstName = '';
      this.form.lastName = '';
      this.codeSent = false;
      this.needRegister = false;
      this.stopCountdown();
    },
    sendCode() {
      this.form.code = '';
      this.form.firstName = '';
      this.form.lastName = '';
      this.codeSent = false;
      this.needRegister = false;
      this.request({ phone: this.form.phone });
    },
    login() {
      this.clearAllErrors();
      if (!this.eula) {
        this.addError('eula', 'Вы должны принять условия');
      } else {
        this.request(this.form);
      }
    },
    request(formData) {
      this.clearAllErrors();
      this.$http.post('/auth/login', formData).then((response) => {
        this.validateFromResponse(response);
        const { data } = response;
        if (data.user && data.token) {
          this.$auth.token(null, data.token, false);
          this.$auth.user(data.user);
          this.clear();
          this.$router.push({ name: 'home' });
        } else if (data.code) {
          this.codeSent = true;
          this.needRegister = data.isNewUser;
          this.startCountDown();
        }
      });
    },
  },
  created() {
    if (this.$auth.user()) {
      this.$router.push({ name: 'home' });
    }
    this.countdown = this.computedCountDown;
  },
};
</script>
