<template>
  <div class="error-container">
    <div class="error-container__code"><span>4</span><span>0</span><span>4</span></div>
    <div class="error-container__title">ОШИБКА</div>
    <div class="error-container__message">СТРАНИЦА НЕ НАЙДЕНА</div>
    <div class="error-container__message">Как вы сюда попали? о_О.</div>
    <div class="error-container__message">Сверху находится меню. Выберите нужный раздел и идите туда</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss">
$wave-time: 600ms;
$wave-size: 1vmin;
$wave-seq-list: 0vmin, -$wave-size, 0vmin, $wave-size, 0vmin, -$wave-size, 0vmin;

@mixin screen-text($font-size) {
  color: #ffffff;
  font-size: $font-size;
  text-align: center;
  text-shadow: ($font-size / 15) ($font-size / 15) rgba(#303030, 0.5);
  user-select: none;
}

.error-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
  }
  &__code {
    @include screen-text(24vmin);
    padding: 1.5vmin 5vmin;
    display: flex;
    span {
      font-weight: bold;
      display: block;
    }
    @for $i from 1 through 3 {
      span:nth-child(#{$i}) {
        animation: error-code-#{$i} $wave-time linear infinite;
      }
    }
  }
  &__title {
    @include screen-text(2.8rem);
    padding: 1.5vmin 10vmin;
  }

  &__message {
    @include screen-text(1.2rem);
    padding: 1.5vmin 30vmin;
  }
}

@for $i from 1 through 3 {
  @keyframes error-code-#{$i} {
    @for $j from 1 through 5 {
      #{($j - 1) * 25}% {
        transform: translateY(nth($wave-seq-list, $j + ($i - 1)));
      }
    }
  }
}
</style>
