<template>
  <div class="header__login" v-cloak>
    <router-link :to="{name: 'auth:login'}">
      Вход/Регистрация
      <img src="../../assets/img/IconUser.svg" alt="Vector" class="header__img">
    </router-link>
  </div>
</template>

<script>
export default {};
</script>
