<template>
  <li class="pwa-prompt" v-if="shown">
    <button class="purple-button" @click="installPWA">
      &#11015; Установить
    </button>
  </li>
</template>

<script>
export default {
  data() {
    return {
      installEvent: undefined,
      shown: false,
    };
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.installEvent = e;
      this.shown = true;
    });
  },
  methods: {
    installPWA() {
      if (this.installEvent) {
        this.installEvent.prompt();
        this.installEvent.userChoice.then((choice) => {
          this.dismissPrompt(); // Hide the banner once the user's clicked
          this.$http.post('/install', { install: choice.outcome === 'accepted' });
        });
      }
    },
    dismissPrompt() {
      this.shown = false;
    },
  },
};
</script>

<style lang="scss">
.pwa-prompt {
  text-align: center;
}
</style>
