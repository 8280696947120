<template>
  <modal
    name="favourite"
    width="280"
    height="140"
    classes="vm--modal catalog-favourite-modal"
    adaptive
    @closed="onModalClosed">
    <div class="catalog-favourite-modal__container">
      <div class="catalog-favourite-modal__form">
        <div class="catalog-favourite-modal__input">
          Темп
          <less-more-input
            v-model="toFavouritesForm.temp"
            :min="minTemp"
            :max="maxTemp"
          ></less-more-input>
        </div>
        <div class="catalog-favourite-modal__input">
          Тональность
          <less-more-input
            v-model="toFavouritesForm.tone"
            :min="minTone"
            :max="maxTone"
          ></less-more-input>
        </div>
      </div>
      <div class="catalog-favourite-modal__btn">
        <button @click.prevent="saveFavourite">Добавить</button>
      </div>
    </div>
  </modal>
</template>

<script>
import LessMoreInput from '@/components/LessMoreInput.vue';

const toFavouritesForm = {
  temp: 0,
  tone: 0,
};

export default {
  components: { LessMoreInput },
  data() {
    return {
      songToFavourite: null,
      songToFavouriteIndex: null,
      toFavouritesForm: JSON.parse(JSON.stringify(toFavouritesForm)),
    };
  },
  computed: {
    minTemp() {
      return this.$store.getters.appConfig('site_min_temp');
    },
    maxTemp() {
      return this.$store.getters.appConfig('site_max_temp');
    },
    minTone() {
      return this.$store.getters.appConfig('site_min_tone');
    },
    maxTone() {
      return this.$store.getters.appConfig('site_max_tone');
    },
  },
  methods: {
    open(item, index) {
      this.songToFavourite = item;
      this.songToFavouriteIndex = index;
      this.$modal.show('favourite');
    },
    saveFavourite() {
      this.$http.post(`/catalog/favourite/${this.songToFavourite.id}`, this.toFavouritesForm).then((response) => {
        if (response.data) {
          this.updateFavourite();
          this.cancelFavourite();
        }
      });
    },
    updateFavourite() {
      this.$emit('update-item', {
        item: this.songToFavourite,
        index: this.songToFavouriteIndex,
        tone: this.toFavouritesForm.tone,
        temp: this.toFavouritesForm.temp,
      });
    },
    cancelFavourite() {
      this.$modal.hide('favourite');
    },
    onModalClosed() {
      this.songToFavourite = null;
      this.songToFavouriteIndex = null;
      this.toFavouritesForm = JSON.parse(JSON.stringify(toFavouritesForm));
    },
  },
};
</script>

<style scoped>

</style>
